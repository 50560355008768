import React, { useContext } from "react";
import { InlineCode } from "web/src/components/markdown/Typography";
import { DocsContext } from "../chrome/DocsContext";

export const renameTsToJs = (filename: string, showTsLanguage: boolean) => {
  if (filename.match(/\.tsx?/) && !showTsLanguage) {
    return filename.replace(".ts", ".js");
  }
  return filename;
};

export const InlineTsFilePath = (props: { filename: string }) => {
  const { showTsLanguage } = useContext(DocsContext);

  const filename = renameTsToJs(props.filename, showTsLanguage);

  return <InlineCode>{filename}</InlineCode>;
};
