import { toaster } from "baseui/toast";
import { sortBy } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import useFetch from "use-http";
import { safelyRunAsyncEventHandler } from "web/src/lib/utils";
import { DocsContext } from "./DocsContext";
import { DocsSelector } from "./DocsSelector";
import { useDocsLocation } from "./nav/useDocsLocation";

export const AppSelector = () => {
  const context = useContext(DocsContext);
  const { loading, post: setCurrentApplication } = useFetch("/api/set-current-app");
  const [currentSlug, setCurrentSlug] = useState(context.currentApp.slug);
  const [location] = useDocsLocation();
  const options = useMemo(
    () =>
      sortBy(
        (context.availableApps || []).map((app) => ({ id: app.slug, label: app.slug })),
        "label"
      ),
    [context.availableApps]
  );

  return (
    <DocsSelector
      label={"Application"}
      disabled={loading}
      options={options}
      value={currentSlug}
      onChange={(item) => {
        safelyRunAsyncEventHandler(async () => {
          try {
            const slug = String(item.id);

            setCurrentSlug(slug);
            await setCurrentApplication({ slug });

            const url = new URL(
              location,
              // url throws if a valid base url isn't passed to the constructor. we don't actually use the protocol or host in this case
              typeof window == "undefined"
                ? process.env.NODE_ENV == "production"
                  ? "https://docs.gadget.dev/"
                  : "https://docs.ggt.dev/"
                : window.location.href
            );

            // Take user to home of current docs area (or docs Home if none), because they may be on a page that won't exist if they change apps
            // We also need to do a page load to trigger reloading the `currentApp` into the context
            url.pathname = url.pathname.replace(/\/api\/.*/, `/api/${slug}`); // let the route select the first development environment by default

            // if we've used a query param to specify the app to load let's clear it so that we use the session app instead
            if (url.searchParams.has("slug")) {
              url.searchParams.delete("slug");
            }

            window.location.hash = "";
            window.location.href = `${url.pathname}${url.search}`;
          } catch (error) {
            toaster.negative("There was an error changing applications, please try again", { autoHideDuration: 3000 });
          }
        });
      }}
    />
  );
};
