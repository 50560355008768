import { build } from "esbuild-wasm";

export async function transpileFileWasmMode(options: { code: string }) {
  // A list of syntaxes that we need to preserve in the code so that esbuild will keep them.
  const preservedSyntaxes: { original: string; replacement: string }[] = [
    // esbuild strips out all comments, we need to replace them with legal comments and esbuild will keep them.
    // Reference: https://esbuild.github.io/api/#legal-comments
    { original: "// ", replacement: "//!@comment " },
    { original: "/**", replacement: "/*!@comment " },
    // esbuild will rename `params` used in the action context to `params2` to avoid name collisions (they think), so we need to replace it.
    // Reference: https://github.com/evanw/esbuild/issues/1027
    { original: "export const params", replacement: "export_const_params" },
    { original: "\\n", replacement: "\\\\n" },
  ];

  const modifiedCode = preservedSyntaxes
    .reduce((code, { original, replacement }) => code.replaceAll(original, replacement), options.code)
    .replaceAll("\n\n", "//!@newline\n");

  // We need to use `build` instead of `transform` because `transform` modifies the `export` syntax and groups them into a single `export` object.
  const buildResult = await build({
    stdin: {
      contents: modifiedCode,
      loader: "tsx",
      resolveDir: ".",
    },
    write: false,
    jsx: "preserve",
    legalComments: "inline",
    minify: false,
    target: "es2022",
    tsconfigRaw: {
      compilerOptions: {
        verbatimModuleSyntax: true,
      },
    },
  });
  const code = buildResult.outputFiles[0].text;

  // Then we remove our markers and restore the original comments and newlines
  return (
    preservedSyntaxes
      .reduce((code, { original, replacement }) => code.replaceAll(replacement, original), code)
      .replaceAll("//!@newline\n", "\n")
      .replaceAll("/* @__PURE__ */ ", "")
      // esbuild will convert numbers with more than 3 zeros to scientific notation, we need to convert them back to a normal number.
      .replace(/\b(\d+e[+-]?\d+)\b/g, (match) => {
        return Number(match).toString();
      })
      .replaceAll("void 0", "undefined")
  );
}
